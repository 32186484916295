import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Μουσικοκινητική Αγωγή";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Το μάθημα απευθύνεται σε παιδιά ηλικίας (3,5—5 ετών). Είναι η πρώτη
          επαφή των παιδιών με το χορό, το ρυθμό και τη μουσική.
        </P>
        <P>
          Αποτελείται από ένα δημιουργικό συνδυασμό ρυθμικών ασκήσεων,
          αυτοσχεδιασμού, βασικών εννοιών του χορού, έτσι ώστε τα παιδιά να
          νιώσουν το φυσικό τους ρυθμό και να καλλιεργήσουν μέσω ενός ευχάριστου
          μαθήματος τη συναισθηματική, νοητική και κινητική τους ανάπτυξη.
        </P>
      </Section.Main>
    </Layout>
  );
}
